import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import footer from '../images/footer.svg';

export default function Footer() {
    return (
        <Navbar fixed='bottom' className='Footer'>
            <Container className='d-flex justify-content-center bg-white'>
                <img src={footer} alt="Payment Options" />
            </Container>
        </Navbar>
    )
}