import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import SwitchLang from './SwitchLang';
import HomeButton from './HomeButton';
import Menu from './Menu';

export default function Header() {
    const [showMenu, setShowMenu] = useState(false);

    const handleShowMenu = () => setShowMenu(true);
    const handleCloseMenu = () => setShowMenu(false);

    const menuItems = ['instruction', 'refund', 'offer', 'policy', 'contacts'];
    const location = useLocation();
    const isStaticPage = menuItems.includes(location.pathname.slice(1));

    return (
        <>
            <Navbar fixed='top' className={isStaticPage ? 'HeaderOpaque' : ''}>
                <Container>
                    {
                        !isStaticPage ? <SwitchLang /> : <HomeButton />
                    }
                    <Button className='MenuButton ms-auto' size='lg' onClick={handleShowMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </Container>
            </Navbar>

            <Menu show={showMenu} handleClose={handleCloseMenu} menuItems={menuItems} />
        </>
    )
}
