import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

export default function HomeButton() {
    return (
        <div className="home-btn">
            <Link to={'/'}>
                <FontAwesomeIcon icon={faHome} size='lg' />
            </Link>
        </div>
    )
}