import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';

function Menu({t, show, handleClose, menuItems}) {

    const classNameButton = 'btn-lg w-100 mb-3 MenuItemButton';

    return (
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton />
            <Offcanvas.Body className='d-flex flex-column justify-content-center align-items-center vh-100'>
                <Nav className='w-100 text-center'>
                    {menuItems.map(item => {
                        return (
                            <Button key={item} as={Link} to={`/${item}`} onClick={handleClose} className={classNameButton}>
                                {t(`menu.${item}`)}
                            </Button>
                        )
                    })
                    }
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

Menu.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Menu);