import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';

function AboutPage() {
    return (
        <></>
    );
}

AboutPage.propTypes = {
    t: PropTypes.func.isRequired,
}

export default translate(AboutPage);