import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';

function ContactsPage({t}) {
    return (
        <Container>
            <Card className="StaticPageContent border-0">
                <Card.Body>
                    <Card.Title className='text-center'>{t('menu.contacts')}</Card.Title>
                    <Card.Subtitle className='text-center'>{t('contacts.title')}</Card.Subtitle>
                    <Card.Text className='text-center pb-2'>{t('contacts.cooperation')}:</Card.Text>
                    <p className='text-center pb-2'>
                        <a href="tel:+380504233100">+38 (050) 423-31-00</a>
                    </p>
                    <Card.Text className='text-center pb-2'>{t('contacts.complaints')}:</Card.Text>
                    <p className='text-center pb-2'>
                        <a href="tel:+380504000468">+38 (050) 400-04-68</a>
                    </p>
                    <Card.Text className='text-center pb-2'>E-mail:</Card.Text>
                    <p className='text-center pb-2'>
                        <a href="mailto:vodomat@roganskaya.com.ua">vodomat@roganskaya.com.ua</a>
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

ContactsPage.propTypes = {
    t: PropTypes.func.isRequired,
}

export default translate(ContactsPage);