import Container from 'react-bootstrap/Container';

export default function Document({ url }) {
    return (
        <Container>
            <iframe
                src={`${url}&embedded=true`}
                title="Offer"
                style={{ width: '100%', height: '100vh' }}
            />
        </Container>
    )
}